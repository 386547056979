import nl from 'date-fns/locale/nl'
import { format as dateFormatter } from 'date-fns'

const formatOptions = {
  locale: nl,
  weekStartsOn: 1,
}

export function showJustDate(apiDateString) {
  if (!apiDateString) return ''
  // console.log('apiDateString', apiDateString)
  const date = new Date(apiDateString)
  // console.log('date', date)

  return dateFormatter(date, 'dd-MM-yyyy', formatOptions)
}

export function formatDateTimeString(apiDateString) {
  if (!apiDateString) return ''
  // console.log('apiDateString', apiDateString)
  const date = new Date(apiDateString)

  // console.log('date', date)

  return dateFormatter(date, 'dd-MM-yyyy HH:mm:ss', formatOptions)
}
