<template>
  <div class="content-container">
    <PageTitle :title="$t('PROFESSIONAL_OVERVIEW.PROFESSIONALS')" class="page-title">
    </PageTitle>
    <h1 class="page-section-title section-title">{{ $t('PROFESSIONAL_OVERVIEW.OVERVIEW') }}</h1>

    <CustomTable
      ref="customTable"
      :options="options"
      :less-padding="true"
      :table-data="searchResults.items"
      :pagination-data="searchResults"
      :search-bar-options="
        {
          placeholder: $t('TEACHERS_HELPDESK.SEARCH_PLACEHOLDER'),
          searchId: 'search-teacher'
        }"
      :search-function="manageSearch"
      :filter-data="filterData"
      @searchparams="(params) => storedSearchParams = params"
    >
      <template #name="props">
        <div> {{ props.rowData.name }}</div>
      </template>
      <template #email="props">
        <div><a :href="'mailto:' + props.rowData.email" class="link-main">{{ props.rowData.email }}</a></div>
      </template>
      <template #createdAt="props">
        <div> {{ props.rowData.createdAtString }}</div>
      </template>
      <template #lastLogin="props">
        <div> {{ props.rowData.lastLoginString }}</div>
      </template>
      <template #accountState="props">
        <div v-if="props.rowData.accountState" :class="props.rowData.accountState.customClass" class="approval-box">
          {{ props.rowData.accountState.name }}
        </div>
      </template>
      <template #actions="props">
        <div class="action-button-group right">
          <ActionButton
            v-if="!props.rowData.isApproved"
            :text="$t('APPROVE')"
            :icon="require('../../../assets/icons/icn_accept.svg')"
            custom-class="button-blue"
            class="action approve"
            @click="approveProfessional(props.rowData)"
          />
          <ActionButton
            :text="$t('DELETE')"
            :icon="require('../../../assets/icons/icn_delete.svg')"
            custom-class="button-red"
            class="action"
            @click="deleteProfessional(props.rowData)"
          />
        </div>
      </template>
      <template #right-side-info>
        <p>{{ searchResults.totalItems + " " }} {{ $t('PROFESSIONAL_OVERVIEW.TOTAL') }} </p>
      </template>
    </CustomTable>

    <BasicModal v-if="isBasicVersionDisplayed"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { computed, ref } from 'vue'
import { getTableOptions, PROFESSIONALS_TABLE } from '@/utils/helpers/customTable/CustomTableHelper'
import { GET_PROFESSIONALS, DELETE_TEACHER } from '@/store/modules/helpdesk/actions'
import { GET_TEACHER_TYPES } from '@/store/modules/auth/actions'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import {formatDateTimeString, showJustDate} from '@/utils/helpers/DateFormatter'
import BasicButton from '../../../components/elements/basic/BasicButton.vue'
import { SET_TOAST_DATA } from '@/store/mutations'

import BasicModal from '@/components/elements/basicModal/BasicModal'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import {USER_TYPE_IDS} from "@/models/UserTypeIds";

export default {
  name: 'ProfessionalOverview',
  components: {
    PageTitle,
    CustomTable,
    ActionButton,
    BasicModal,
  },
  setup(props, context) {
    const router = useRouter()
    const store = useStore()
    const storedSearchParams = ref({});

    const accountStateOptions = [
      // {
      //   id: 1,
      //   name: 'In afwachting goedkeuring',
      //   customClass: 'pending'
      // },
      {
        id: 2,
        name: 'Goedgekeurd',
        customClass: 'approved',
      },
      // {
      //   id: 3,
      //   name: 'Uitgenodigd door helpdesk, nog geen wachtwoord ingesteld',
      //   customClass: 'invitedByHelpdesk',
      // },
      // {
      //   id: 4,
      //   name: 'Uitgenodigd door leraar, nog geen wachtwoord ingesteld',
      //   customClass: 'invitedByTeacher',
      // },
      {
        id: 5,
        name: 'Oud MM account, nog geen wachtwoord ingesteld',
        customClass: 'oldMMAccount',
      },
      {
        id: 6,
        name: 'E-mailadres niet geverifieerd',
        customClass: 'emailNotVerified',
      },
    ]


    const searchResults = computed(() => {
      const teachers = store.getters.getTeachers

      // console.log('store.getters.getTeachers', store.getters.getTeachers)
      if (teachers.items && teachers.items.length) {
        teachers.items.forEach((teacher) => {
          teacher.lastLoginString = teacher.lastLogin ? formatDateTimeString(teacher.lastLogin) : 'Nog nooit'
          teacher.createdAtString = formatDateTimeString(teacher.createdAt)
          // console.log('teacher', teacher)
          if (!teacher.emailVerified) {
            teacher.accountState = accountStateOptions[1]
          }
          else if (!teacher.hasSetPassword) {
            teacher.accountState = accountStateOptions[2]
          } else {
            teacher.accountState = accountStateOptions[0]
          }
        })
      }

      // console.log('teachers', teachers)
      return teachers
    })
    const options = computed(() => getTableOptions(PROFESSIONALS_TABLE))


    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_PROFESSIONAL ||
      activeModal.value === MODAL_NAMES.DECLINE_PROFESSIONAL ||
      activeModal.value === MODAL_NAMES.APPROVE_PROFESSIONAL
    )

    function approveProfessional(data) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.APPROVE_PROFESSIONAL(data.name), () => approveOrDecline(true, data), null, null, MODAL_NAMES.APPROVE_PROFESSIONAL)
    }

    function declineProfessional(data) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DECLINE_PROFESSIONAL(data.name), () => approveOrDecline(false, data), null, null, MODAL_NAMES.DECLINE_PROFESSIONAL)
    }

    function deleteProfessional(data) {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DELETE_PROFESSIONAL(data.name), () => postDelete(data), null, null, MODAL_NAMES.DELETE_PROFESSIONAL)
    }



    function approveOrDecline(approve, professional) {
      const formData = new FormData()
      formData.append('teacherId', professional.userId)
      ApiHandler.post(`helpdesk/teacher/${(approve ? 'approve' : 'decline')}-teacher`, formData).then(response => {
        manageSearch(storedSearchParams.value)
        modalController.value.resetActiveModal()
      })
    }

    function postDelete(professional) {
      store.dispatch(DELETE_TEACHER, professional.userId).then((response) => {
        manageSearch(storedSearchParams.value)
        modalController.value.resetActiveModal()
        store.commit(SET_TOAST_DATA, { text: 'Professional successvol verwijderd', src: require('../../../assets/icons/icn_successful.svg') })
      })
    }


    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      searchProfessionals(payload)
    }

    const filterData = computed(() => {
      return {
        placeholder: 'Filter',
        singleSelection: false,
        dropdownOptions:
          [
            {
              name: 'Account status',
              type: 'accountState',
              singleSelection: true,
              options: accountStateOptions
            }
          ],
      }
    })

    function searchProfessionals(payload) {
      // console.log('payload', payload)
      store.dispatch(GET_PROFESSIONALS, payload)
    }

    getTeacherTypes()

    function getTeacherTypes() {
      store.dispatch(GET_TEACHER_TYPES)
    }


    function teacherDetails(data) {
      const teacherId = data.userId
      router.push({ name: ROUTE_NAMES_HELPDESK.TEACHER_DETAILS, params: { teacherId: teacherId } })
      // console.log(data)
    }

    return {
      searchResults,
      filterData,
      storedSearchParams,
      options,
      manageSearch,
      ROUTE_NAMES_HELPDESK,
      teacherDetails,
      showJustDate,

      isBasicVersionDisplayed,
      deleteProfessional,
      approveProfessional,
      declineProfessional
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

// todo: fix duplication with TeachersOverview
.approval-box{
  padding: 0.4rem;
  text-align: center;
  border-radius: rem(5);
  min-width: rem(120);
  max-width: rem(270);

  //&.pending {
  //  color: rgb(248,170,105);
  //  background-color: #FDF2E8;
  //}
  &.approved {
    color: #37c6be;
    background-color: #e5f7f6;
  }
  //&.invitedByTeacher, &.invitedByHelpdesk {
  //  color: #ccb528;
  //  background-color: #f7f7e5;
  //}
  &.oldMMAccount {
    background-color: #f6f7fa;
    color: #8d877c;
  }
  &.emailNotVerified {
    color: #aa0019;
    background-color: #f7d3ce;
  }
}

.create-teacher {
  font-size: rem(16);
  width: rem(210);
  height: rem(41);
  padding: 0;
  line-height: rem(41);
}

.top-right-buttons-wrapper{
  display: flex;
  gap: rem(15);
}

.export-email-button{
  font-size: rem(16);
  height: rem(41);
  padding: 0 rem(10);
  line-height: rem(41);
  background: #0E4E95
}
</style>
